<template>
  <v-app
   style="overflow: hidden;"
  >
    <pages-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'Index',

    components: {
      PagesCoreView: () => import('@/components/login/core/View'),
    },

    created () {
      this.$vuetify.theme.dark = false
    },

    beforeDestroy () {
      this.$vuetify.theme.dark = false
    },
  }
</script>
